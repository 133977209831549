// src/SuccessPage.js
import React from 'react';
import { Link } from 'react-router-dom';

function SuccessPage() {
    return (
        <div>
            <h2>Commande Complétée avec Succès!</h2>
            <p>Votre commande a été enregistrée avec succès.</p>
            <Link to="/">Retour à la page d'accueil</Link>
        </div>
    );
}

export default SuccessPage;
