// src/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';

function HomePage() {
    return (
        <div>
            <h1>Bienvenue sur la page d'accueil</h1>
            <ul>
                <li>
                    <Link to="/commande">Créer une nouvelle commande</Link>
                </li>
                <li>
                    <Link to="/article/create">Créer un nouvel article</Link>
                </li>
                <li>
                    <Link to="/articles">Voir la liste des articles</Link>
                </li>
            </ul>
        </div>
    );
}

export default HomePage;
