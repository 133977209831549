import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

// Your Auth0 configuration
const domain = "dev-wvmv3e0ffagdq4jq.us.auth0.com";
const clientId = "YJW7nk66l8hqqUM5bLVMfFq738L1tFF2";

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);