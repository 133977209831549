// src/ErrorPage.js
import React from 'react';
import { Link } from 'react-router-dom';

function ErrorPage() {
    return (
        <div>
            <h2>Erreur lors de la Commande</h2>
            <p>Une erreur est survenue lors de l'enregistrement de votre commande. Veuillez réessayer.</p>
            <Link to="/commande">Retour à la page de commande</Link>
        </div>
    );
}

export default ErrorPage;
