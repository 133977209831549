import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ArticleForm from './ArticleForm'; // Import your article form component
import CommandeForm from './CommandeForm';
import ClientInfoForm from './ClientInfoForm';
import SuccessPage from './SuccessPage';
import ErrorPage from './ErrorPage';
import HomePage from './HomePage';
import ArticlesList from './ArticlesList';

function App() {
    const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

    // Redirect to login if not authenticated, only when the loading state is complete
    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    // If the authentication status is still loading, return a loading indicator
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <div className="App">
                {isAuthenticated ? (
                    <div>
                        <button onClick={() => logout({ returnTo: window.location.origin })}>
                            Log out
                        </button><br/>
                        <Routes>
                        <Route path="/" element={<HomePage />} />
                            <Route path="/article/create" element={<ArticleForm />} />
                            <Route path="/articles" element={<ArticlesList />} />
                            <Route path="/commande" element={<CommandeForm />} />
                            <Route path="/commande/:id" element={<ClientInfoForm />} />     
                            <Route path="/success" element={<SuccessPage />} />
                            <Route path="/error" element={<ErrorPage />} /> 
                            {/* Redirect any other route to /home */}
                            <Route path="*" element={<Navigate to="/home" />} />
                        </Routes>
                    </div>
                ) : (
                    // Placeholder content while redirection is being handled
                    <div>Redirecting...</div>
                )}
            </div>
        </Router>
    );
}

export default App;
