// src/ClientInfoForm.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function ClientInfoForm() {
    const { id } = useParams();
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [telephone, setTelephone] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!nom || !prenom || !telephone) {
            setError('Tous les champs sont obligatoires');
            return;
        }

        try {
            await axios.post(`https://bd.delightsrecipes.com/api/commandes/${id}/client`, {
                nom,
                prenom,
                telephone,
            });
            navigate('/success'); // Redirect to success page
        } catch (err) {
            setError('Erreur lors de l\'enregistrement des informations client');
            navigate('/error'); // Redirect to error page
        }
    };

    return (
        <div>
            <h2>Information Client</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nom:</label>
                    <input
                        type="text"
                        value={nom}
                        onChange={(e) => setNom(e.target.value)}
                    />
                </div>
                <div>
                    <label>Prénom:</label>
                    <input
                        type="text"
                        value={prenom}
                        onChange={(e) => setPrenom(e.target.value)}
                    />
                </div>
                <div>
                    <label>Téléphone:</label>
                    <input
                        type="text"
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                    />
                </div>
                <button type="submit">Enregistrer les Informations Client</button>
            </form>
        </div>
    );
}

export default ClientInfoForm;
