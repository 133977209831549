// src/CommandeForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function CommandeForm() {
    const [articleName, setArticleName] = useState('');
    const [quantite, setQuantite] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!articleName || !quantite) {
            setError('Article name and quantity are required');
            return;
        }

        try {
            const response = await axios.post(`https://bd.delightsrecipes.com/api/commandes`, {
                article_name: articleName,
                quantite: quantite,
            });
            navigate(`/commande/${response.data.id}`);
        } catch (err) {
            setError('Failed to create command');
        }
    };

    return (
        <div>
            <h2>Create Command</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Article Name:</label>
                    <input
                        type="text"
                        value={articleName}
                        onChange={(e) => setArticleName(e.target.value)}
                    />
                </div>
                <div>
                    <label>Quantité:</label>
                    <input
                        type="number"
                        value={quantite}
                        onChange={(e) => setQuantite(e.target.value)}
                    />
                </div>
                <button type="submit">Create Command</button>
            </form>
        </div>
    );
}

export default CommandeForm;
