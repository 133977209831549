// src/ArticlesList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ArticlesList() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        async function fetchArticles() {
            try {
                const response = await axios.get(`https://bd.delightsrecipes.com/api/articles`);
                console.log(response)
                setArticles(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des articles', error);
            }
        }

        fetchArticles();
    }, []);

    return (
        <div>
            <h2>Liste des articles</h2>
            <table border="1">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Titre</th>
                        <th>Description</th>
                        <th>Date de Création</th>
                    </tr>
                </thead>
                <tbody>
                    {articles.map((article) => (
                        <tr key={article.id}>
                            <td>{article.id}</td>
                            <td>{article.title}</td>
                            <td>{article.description}</td>
                            <td>{article.created_at}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ArticlesList;
